import { Icon } from 'antd';
import React, { useCallback } from 'react';

import styles from './index.less';

function ScrollControl({
  topId = '',
  bottomId = '',
  visible = true,
  className = '',
  mode = 'start',
  isSaintsTheme
}) {
  const scrollTo = useCallback(
    type => {
      const eleId = type === 'top' ? topId : bottomId;
      const targetEle = document.getElementById(eleId);
      if (targetEle) {
        targetEle.scrollIntoView({
          block: type === 'top' ? 'start' : 'end',
          behavior: 'smooth'
        });
      }
    },
    [topId, bottomId]
  );

  return (
    // <div
    //   className={`${styles.scrollControl} ${visible ? '' : styles.hidden
    //     } ${className}`}
    // >
    //   <i class="material-icons" onClick={() => scrollTo('top')}>arrow_drop_down_circle</i>
    //   <i class="material-icons" onClick={() => scrollTo('botton')}>arrow_drop_down_circle</i>
    // </div>
    <div className={`${styles.backTop} ${styles.scrollControl} ${visible ? '' : styles.hidden} ${className}`}>
      <div className={styles.up} onClick={() => scrollTo('top')} style={{background:isSaintsTheme ? "#fff" : null}}>
        <img src={isSaintsTheme ? require('../../assets/newBing/arrow_drop_up.svg') : require('../../assets/newBing/down.png')}></img>
      </div>
      <div className={styles.down} onClick={() => scrollTo('bottom')} style={{background:isSaintsTheme ? "#fff" : null}}>
        <img src={isSaintsTheme ? require('../../assets/newBing/arrow_drop_up.svg') : require('../../assets/newBing/down.png')}></img>
      </div>
    </div>
    
  );
}

export default React.memo(ScrollControl);
