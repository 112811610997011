import EventEmitter from 'eventemitter3';
import { fetchSearchList } from 'utils/fetchFromExtension';

const defaultLabel = {};
export function createProductEventParams(productData = {}) {
  const labels = productData.om_label || defaultLabel;
  return {
    // 商品ae id
    paid: productData.id,
    // 供应商
    supplier: labels.supplier || '',
    // 曝光商品国家
    // country: productData.country,
    // 曝光商品分类
    type: productData.category_lv1,
    // 商品物流速度
    shipping_speed: labels.shipping_speed || '',
    // 商品版本
    version: labels.version || '',
    // 是否新商品
    is_new: labels.is_new || false,
    // 是否是奖励商品
    is_reward: labels.is_reward || false,
    is_ten_days: labels.is_ten_days || false,
    title: productData.name,
    partition: labels.partition || '',
    // dsers个性推荐token
    token: productData.token,
  };
}

export const createClickEvent = (productData, index) => {
  return {
    event_action: 'click',
    event_label: 'product_pool',
    ...createProductEventParams(productData),
    pos: index
  };
};
// 点击商品上报
export const sendGoodsClickEvent = (productData, index) => {
  return {
    event_category: 'product',
    ...createClickEvent(productData, index)
  };
};

// 点击import上报
export const importButtonClickEvent = (productData, index) => {
  return {
    event_category: 'product_import_button',
    ...createClickEvent(productData, index)
  };
};

const BARGAIN_TIME_LIMIT = 86400 * 1000;
// 临时解决方案 24小时内bargain过的商品记录在前端

export function setBargainedProduct(productData) {
  try {
    const currentList = JSON.parse(
      localStorage.getItem('BARGAINED_PRODUCT_LIST') || '[]'
    );
    currentList.push({
      id: productData.id,
      lastBargainTime: +new Date()
    });
    localStorage.setItem('BARGAINED_PRODUCT_LIST', JSON.stringify(currentList));
    return currentList;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export function getBargainedProducts() {
  try {
    const nowTime = +new Date();
    const bargainedList = JSON.parse(
      localStorage.getItem('BARGAINED_PRODUCT_LIST') || '[]'
    ).filter(item => {
      return nowTime - item.lastBargainTime < BARGAIN_TIME_LIMIT;
    });
    localStorage.setItem(
      'BARGAINED_PRODUCT_LIST',
      JSON.stringify(bargainedList)
    );
    return bargainedList;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export function getProducts(products, bargainedProducts) {
  const result = products.map(productItem => {
    return {
      ...productItem,
      bargained:
        bargainedProducts.findIndex(item => item.id === productItem.id) > -1
    };
  });
  return result;
}

export const bargainEventBus = new EventEmitter();

const sortMap = {
  '-orders': 'total_tranpro_desc',
  '-new': 'create_desc',
  '+price': 'price_asc',
  '-price': 'price_desc'
};

const limitedKeywords = [
  'women',
  'femme',
  'mulheres',
  'vaginal',
  'sex',
  'sexe',
  'sexo',
  'sexy',
  'sex toys',
  'jouets sexuels',
  'brinquedos sexuais',
  'pornography',
  'pornographie',
  'pornografia',
  'godemiché',
  'dildo',
  'erotic',
  'érotique',
  'erótico',
  'clitoris',
  'clitóris',
  'clitoris'
];

export const checkParamsForExtFetch = params => {
  const keyword = (params.q || '').toLowerCase();
  return (
    params.q &&
    !params.partition &&
    !params.reward_status &&
    // 这个关键词在这个品类下会搜出很多情趣用品 这里过滤掉这种情况
    !(
      ['beauty-health', ''].includes(params.category) &&
      limitedKeywords.includes(keyword)
    )
  );
};

export const fetchSearchResultFromExt = async (
  params,
  dsersResult,
  dsersCount
) => {
  let result = dsersResult;
  let resCount = dsersCount;
  try {
    const fastDelivery =
      /delivery/.test(params.partition) || params.delivery_days_status;
    const aeLang = params.lang === 'pt_PT' ? 'pt_BR' : params.lang;
    const country = aeLang.split('_')[1];
    const extFetchRes = await fetchSearchList({
      searchText: params.q,
      lang: params.lang === 'pt_PT' ? 'pt_BR' : params.lang,
      shipFrom: params.shipping_from,
      isFastShipping: fastDelivery ? 'y' : 'n',
      freeReturn: params.free_return ? 'y' : 'n',
      fourStarAbove: 'y',
      minPrice: params.min_price,
      maxPrice: params.max_price,
      page: params.current,
      category: params.category,
      sort: sortMap[params.sort] || '',
      country
    });
    // 去重
    const uniqueMap = {};
    result.forEach(item => {
      uniqueMap[item.id] = true;
    });
    let list = extFetchRes.data.result.items.map(item => {
      const sellingPoints = item.sellingPoints || [];
      const tags = item.tags || {};
      const isFreeReturn =
        sellingPoints.findIndex(point => {
          return point.sellingPointTagId === '203098023';
        }) > -1;
      const freightTag = tags?.logistics_tag?.tagText || '';
      const deliveryNumber = freightTag.match(/[0-9]+/) || [];
      const isTendays = ['10', '7', '5', '3'].includes(deliveryNumber[0]);
      const prices = item.umpPrices?.sale_price || {};
      return {
        image: item.imageUrl.replace(/^\/\//, 'https://'),
        average_star: item.starRating,
        orders: item.tradeDesc.split(' ')[0],
        // 添加这个自定义专区及供应商以区分商品
        om_label: {
          partition: 'search_by_ext',
          supplier: 'aliexpress',
          is_ten_days: isTendays
        },
        is_free_return: isFreeReturn,
        price: {
          extResPrice: prices.formattedPrice.replace(' - ', '-') || 'US $',
          discount: prices.discount || 0
        },
        id: item.productId,
        name: item.title
      };
    });
    list = list.filter(item => !uniqueMap[item.id]);
    result = [...result, ...list].slice(0, 60);
    const aliResCount = extFetchRes.data.result.resultCount;
    if (aliResCount < 60) {
      if (aliResCount + resCount > 60) {
        resCount = 60;
      } else {
        resCount = aliResCount + resCount;
      }
    } else {
      resCount = aliResCount;
    }
    if (resCount > 3600) {
      resCount = 3600;
    }
  } catch (error) {
    console.log(error);
  }
  // console.log(result, resCount);
  return {
    list: result,
    pageCount: resCount
  };
};
