import { Button, Icon } from 'antd';
import React, { useState } from 'react';

import styles from './Buttons.less';

const LoadingButton = React.memo(({ title, onClick }) => {
  const [loading, setLoading] = useState(false);
  const closeLoading = () => setLoading(false);
  const hanldeClick = () => {
    setLoading(true);
    onClick(closeLoading);
  };
  return (
    <Button
      className={styles.loadingButton}
      loading={loading}
      onClick={hanldeClick}
    >
      {loading ? null : title}
    </Button>
  );
});

const CameraButton = ({ onClick, className }) => {
  return (
    <span
      role="button"
      tabIndex="-1"
      className={`${styles.cameraButton} ${className}`}
      onClick={onClick}
      title="search products by image"
    >
      <i class="material-icons">photo_camera</i>
    </span>
  );
};

export { LoadingButton, CameraButton };
