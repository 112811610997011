import { Button, Icon, message, Modal, Upload } from 'antd';
import React, { useState } from 'react';
import intl from 'react-intl-universal';
import ImgCrop from 'antd-img-crop';

import styles from './UploadImageModal.less';

const { Dragger } = Upload;

function UploadImageModal({
  visible,
  onCancel,
  onOk,
  className,
  wrapClassName,
  selectType
}) {
  const [previewImg, setPreviewImg] = useState('');
  const [originFile, setOriginFile] = useState(null);
  const [hasFooter, setHasFooter] = useState(false);
  const beforeUpload = async file => {
    // console.log(file);
    const validImg = /image/.test(file.type) && file.size < 8192000;
    if (!validImg) {
      message.error(intl.get('supplier_optimizer.only_img'));
      return Promise.reject();
    }
    const imgBase64 = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
    });
    setPreviewImg(imgBase64);
    setOriginFile(file);
    setHasFooter(true)
    return Promise.reject();
  };

  const handleUpload = () => {
    console.log('test====>1111=====>',originFile);
    onOk(originFile);
    setPreviewImg('')
    window.dtag.set({
      event:'click',
      module: selectType === 'Tmall' ? 'tmall' : 'Aliexpress',
      action: selectType === 'Tmall' ? 'tmall_figure_search': 'Aliexpress_figure_search',
      custom_data:{
        'click_type': 'go_supplier_upImage_supplier'
      }
    })
  };

  const cancleModal = () => {
    setPreviewImg('')
    onCancel();
  }
  // const beforeCrop = file => {
  //   console.log(file);
  //   const validImg = /image/.test(file.type) && file.size < 8192000;
  //   if (!validImg) {
  //     message.error(intl.get('supplier_optimizer.only_img'));
  //   }
  //   return validImg;
  // };

  return (
    <Modal
      visible={visible}
      onCancel={cancleModal}
      // onOk={handleUpload}
      width={560}
      footer={null}
    >
      <div className={styles.title}>
        {intl.get('supplier_optimizer.import_picture')}
      </div>
      <div className={styles.subTitle}>
        {intl.get('supplier_optimizer.upload_tip')}
      </div>
      {/* <ImgCrop beforeCrop={beforeCrop}> */}
      <Dragger
        style={{ width: '512px', height: '512px' }}
        className={styles.dragger}
        beforeUpload={beforeUpload}
        accept="image/png,image/jpg,image/jpeg"
      >
        {previewImg ? (
          <div>
            <img className={styles.previewImg} src={previewImg} />
            {/* <Icon type="close" /> */}
          </div>
        ) : (
          <div className={styles.uploadCon}>
            {/* <Icon className={styles.pictureIcon} type="picture" /> */}
            <i style={{ fontSize: '108px', color: '#D7D7D7' }} class="material-icons">insert_photo</i>
            <Button className={styles.uploadBtn}>{intl.get('supplier_optimizer.uploadimage')}</Button>
            <div>{intl.get('supplier_optimizer.click_or_drag')}</div>
          </div>
        )}
      </Dragger>
      {/* </ImgCrop> */}
      {hasFooter ? <Button className={styles.okBtn} onClick={handleUpload}>OK</Button> : null}
    </Modal>
  );
}

export default React.memo(UploadImageModal);
