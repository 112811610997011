import { Get } from 'utils/request';
import { dsersBFFApiHost } from 'common/sysconfig';

const getStsToken = () => {
  return Get(`${dsersBFFApiHost}/dsers-infra-bff/sts`, {
    isCustomApi: true
  });
};

export const initOssClient = async () => {
  const res = await getStsToken();
  if (res.data) {
    const client = new OSS({
      region: res.data.region,
      accessKeyId: res.data.accessKeyId,
      accessKeySecret: res.data.accessKeySecret,
      bucket: res.data.bucket,
      stsToken: res.data.securityToken
    });
    return client;
  }
};
