import { emitCustomEvent } from 'utils/dsersCustomEvent';
import { getLanguage, replaceUrlToHttps } from 'utils/utils';

export const createAliItemUrl = id =>
  `https://www.aliexpress.com/item/${id}.html?dsref=similar`;

export const addImageSizeParams = url =>
  `${replaceUrlToHttps(url)}_50x50.jpg_.webp`;

export const getImgPopupContainer = id =>
  document.getElementById(id) || document.body;

const getDomain = url => {
  if (url.indexOf('//') > -1) {
    const st = url.indexOf('//', 1);
    let domain = url.substring(st + 1, url.length);
    const et = domain.indexOf('/', 1);
    domain = domain.substring(1, et);
    const strArr = domain.split('.');
    const str = strArr[strArr.length - 2] + '.' + strArr[strArr.length - 1];
    return str;
  }
  return url;
};

export const removeImgParams = url => {
  if (!url || !url.replace) {
    return '';
  }
  let newUrl = url;
  if (getDomain(url) === 'alicdn.com') {
    newUrl = url.replace(/\.(jpg|png|jpeg).*/, '.$1');
  }
  return newUrl;
};

export const testUrl = url => {
  if (!url) {
    return false;
  }
  return /(http|https):\/\//.test(url);
};

export const postPageLoadCustomEvent = (params = {}) => {
  const { token } = params;
  emitCustomEvent({
    event_category: 'page',
    event_action: 'exposure',
    event_label: 'dsers',
    page_id: 'optimizer',
    page_url: location.href,
    lang: getLanguage().replace('-', '_'),
    token
  });
};

export const postSearchResultEvent = params => {
  const {
    searchResult,
    errMessage,
    inputUrl,
    imgUrl,
    imgCode,
    scene,
    ref,
    resCategory,
    refAction = '',
    token
  } = params;
  console.log(errMessage, 'errMessage-=-=-=1');
  emitCustomEvent({
    cause_img_url: inputUrl,
    event_category: 'page',
    event_action: 'search',
    event_label: 'optimizer',
    img_url: imgUrl,
    img_code: imgCode,
    scene,
    ref,
    search_result: searchResult,
    err_msg: errMessage,
    result_product_category: resCategory,
    ref_big_class: refAction,
    token
  });
};
